import React from 'react'
import styles from './alert.module.css'
import { useNavigate } from 'react-router-dom';


const Alert = ({ alert, setAlert }) => {
    const navigate = useNavigate();
    const clearAlert = () => {
        setAlert(null);
    }
    if(alert?.message === "Invalid Credentials"){
        navigate('/login');
    }
    return (
        <>
            {alert && (<div className={`${styles.alert} ${styles[alert.type]}`} >
                {alert.message}
                <button type="button" className={styles.closeBtn} onClick={clearAlert}>x</button>
            </div>)}
        </>
    )
}

export default Alert