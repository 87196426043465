import React, { useEffect, useRef, useState } from 'react'
import styles from './products.module.css'




const Products = ({ showAlert }) => {
    const searchRef = useRef(null);
    const [QueryParams, setQueryParams] = useState({ verified: "none", page: 1, search: "" })
    const updateQueryParams = (queryParams) => {
        const url = new URL("http://localhost:5000/products");

        if (queryParams.verified !== "none") {
            url.searchParams.set("verified", queryParams.verified);
        } else {
            url.searchParams.delete("verified");
        }

        if (queryParams.search.length > 1) {
            url.searchParams.set("search", queryParams.search);
        } else {
            url.searchParams.delete("search");
        }

        if (queryParams.page > 1) {
            url.searchParams.set("page", queryParams.page);
        } else {
            url.searchParams.delete("page");
        }

        return url;
    };
    useEffect(() => {
        const url = updateQueryParams(QueryParams);
        getProducts(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [QueryParams])
    const getProducts = async (url) => {
        const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'auth-Token': localStorage.getItem('token')
            },
        });
        const json = await response.json();
        console.log(json)
        if (json.success) {
            setProducts(json)
        }
        else {
            showAlert(json.error, "warning")
        }
    }
    const onSearchChange = (e) => {
        e.preventDefault();
        setQueryParams((prev) => ({ ...prev, search: searchRef.current.value, page: 1 }))
    }
    const [Products, setProducts] = useState({ products: [], page: 1, count: 100 });
    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainTop}>
                Create Users
            </div>
            <div className={styles.tableDivOuter}>
                <div className={styles.tableDivTop}>
                    <form className={styles.search} onSubmit={onSearchChange}>
                        <span className="material-icons search-icon">search</span>
                        <input type="text" placeholder="Search" ref={searchRef}/>
                    </form>
                    <form className={styles.filter}>
                        <select name="filter" id="filter" onChange={(e) => { setQueryParams((prev) => ({...prev, verified: e.target.value}))}}>
                            <option value="none">All</option>
                            <option value="true">Verified</option>
                            <option value="false">Rejected</option>
                        </select>
                        <span className="material-icons filter-icon">filter_list</span>
                    </form>
                </div>
                <table className={styles.tableMain}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Brand</th>
                            <th>Category</th>
                            <th>Price</th>
                            <th>Mrp</th>
                            <th>Sellular Verified</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Products.products.map((product) => (
                            <tr key={product?.pName}>
                                <td>
                                    <div className={styles.applicantTitle}>
                                        {/* <div className={styles.profileIcon}>
                                    <img src="img/profile-temp.jpg" alt="" />
                                </div> */}
                                        <p>{product?.pName}</p>
                                    </div>
                                </td>
                                <td>{product?.pBrand}</td>
                                <td>{product?.pCategoryName}</td>
                                <td>{product?.pPrice}</td>
                                <td>{product?.pMrp}</td>
                                <td>
                                    <div className={`${styles.sellularVerified} ${styles[product?.sellularVerified]}`}>
                                        {product?.sellularVerified.toString()}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* Custom Pagination */}
            <div className={styles.pagination}>
            <p>
                    Total Results : <span className={styles.currentCount}>{Products?.count}</span>
                </p>
                <ul className={styles.pagesNumber}>
                    <li className={QueryParams.page === 1 ? styles.disable : ""} onClick={() => { setQueryParams(prev => ({ ...prev, page: (QueryParams.page > 1) ? (QueryParams.page - 1) : 1 })) }}>Prev</li>
                    <li className={styles.currentPage}>{Products?.page}</li>
                    <li className={Products?.count / 20 < QueryParams.page ? styles.disable : ""} onClick={() => { setQueryParams(prev => ({ ...prev, page: QueryParams.page + 1 })) }}>Next</li>
                </ul>
            </div>
        </div>

    )
}

export default Products