import React, { useState } from 'react'
import styles from './forgotPassword.module.css'
import { Link } from 'react-router-dom';

const ForgotPassword = ({showAlert}) => {
    const [Error, setError] = useState(null);
    const [email, setEmail] = useState("");
    const submitForm = async(e) =>{
        e.preventDefault();
        console.log("1");
        const response = await fetch("http://localhost:5000/forgotpassword", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email }),
        });
        const json = await response.json();
        console.log("2");
        console.log(json);
        if(json.success){
            showAlert("success","Email Sent Successfully");
        }
        else{
            setError(json.error);
            showAlert(json.error,"danger");
        }
    }
    return (
        <>
            <div className={styles.mainContainer}>
                <div className={styles.leftContainer}>
                    <img src="img/college-students.png" alt="" />
                </div>
                <div className={styles.rightContainer}>
                    <div className={styles.sectionMain}>
                        <div className={styles.titleSection}>
                            <div className={styles.logo}>
                                <img src="/img/Sellular Logo.png" alt="" />
                            </div>
                            <h1>SELLULAR</h1>
                            <p>Sellular is a Community, by Enthusiasts,<br /> for Enthusiasts, of Enthusiasts</p>
                        </div>
                        <form className={styles.inputSection} action="/confirm_mail" method="POST" onSubmit={submitForm}>
                            <div className={styles.inputs}>
                                <p className={styles.inputTitle}>Recovery Email Address</p>
                                <input type="email" placeholder="Enter - Recovery Mail" name="email" required onChange={(e)=>setEmail(e.target.value)}/>
                            </div>
                            {Error && (
                                <div className={styles.errorMessage}>
                                    <p>{Error}</p>
                                </div>
                            )}
                            <button className={styles.submitUser} type="submit">Recover</button>
                        </form>
                        <div className={styles.signupSection}>
                            <p>Head back to main page <Link to="/login">Login</Link></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.light} ${styles.x1}`}></div>
            <div className={`${styles.light} ${styles.x2}`}></div>
            <div className={`${styles.light} ${styles.x3}`}></div>
            <div className={`${styles.light} ${styles.x4}`}></div>
            <div className={`${styles.light} ${styles.x5}`}></div>
            <div className={`${styles.light} ${styles.x6}`}></div>
            <div className={`${styles.light} ${styles.x7}`}></div>
            <div className={`${styles.light} ${styles.x8}`}></div>
            <div className={`${styles.light} ${styles.x9}`}></div>
        </>
    )
}

export default ForgotPassword