import React, { useState } from 'react'
import styles from './editCarousel.module.css'

const EditCarousel = ({showAlert}) => {
    const [ErrorMsg, setErrorMsg] = useState(null);
    const [formData, setFormData] = useState({
        carouselImage: '',
        redirectLink: '',
        carouselLocation: '',
    })
    const handleChange = (e) => {
        console.log(e.target.name + ":" + e.target.value);
        if (e.target.name === 'carouselImage') {
            console.log(e.target.name + ":" + e.target.files[0])
            setFormData({
                ...formData,
                [e.target.name]: e.target.files[0],
            });
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        }
    };
    const handleSubmit = async(e) => {
        e.preventDefault();
        setErrorMsg(null);

        const formDataToSend = new FormData();
        formDataToSend.append('carouselImage',formData.carouselImage);
        formDataToSend.append('redirectLink',formData.redirectLink);
        formDataToSend.append('carouselLocation',formData.carouselLocation);

        formDataToSend.forEach((value, parent) => {
            console.log(parent + ":" + value);
        })
        const response = await fetch('http://localhost:5000/editcarousel', {
            method: 'POST',
            headers: {
                'auth-Token': localStorage.getItem('token')
            },
            body: formDataToSend,
        });
        console.log(response)
        const json = await response.json();
        console.log(json);

        if (json.success) {
            showAlert("Data Submitted Successfully","success" );
        }
        else {
            setErrorMsg(json.error);
            showAlert(json.error, 'danger');
        }
    }
    return (
        <section className={styles.mainContainer}>
            <div className={styles.mainTop}>
                <p>Edit Carousel</p>
            </div>
            {ErrorMsg && (
                <div className={styles.errorMessage}>
                    {ErrorMsg}
                </div>
            )}

            <form className={styles.formDiv} method="POST" enctype="multipart/form-data" onSubmit={handleSubmit}>
                <div className={styles.inputSection}>
                    <div className={styles.formSection}>
                        <div className={styles.inputs}>
                            <p className={styles.inputTitle}>Upload Image</p>
                            <label className={styles.carouselSelect} htmlFor="carouselImage">Add Image <span className="material-icons">upload_file</span></label>
                            <input onChange={handleChange} className={styles.carouselImage} type="file" name="carouselImage" id='carouselImage' />
                        </div>
                        <div className={styles.inputs}>
                            <p className={styles.inputTitle}>Image Redirect Link</p>
                            <input onChange={handleChange} type="text" placeholder="Enter the Link to be redirected to" name="redirectLink" required />
                        </div>
                    </div>
                    <div className={styles.formSection}>
                        <div className={styles.inputs}>
                            <p className={styles.inputTitle}>Select Carousel Location</p>
                            <select required onChange={handleChange} name='carouselLocation'>
                                <option value="home">Homescreen</option>
                                <option value="buysell">Buy and Sell</option>
                                <option value="events">Events</option>
                            </select>
                        </div>
                        <button className={styles.submitBtn} type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </section>
    )
}

export default EditCarousel