import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import styles from './login.module.css'

const Login = ({showAlert}) => {
  const [Credentials, setCredentials] = useState({name:"",password:""});
  const navigate = useNavigate();
  const [ErrorMsg, setErrorMsg] = useState(null)

  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await fetch(`http://localhost:5000/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: Credentials.name, password: Credentials.password })
    });
    const json = await response.json();
    console.log(json);
    if(json.success){
      localStorage.setItem('token',json.authToken);
      navigate('/dashboard');
    }
    else{
      setErrorMsg(json.error);
      showAlert(json.error,'danger');
    }
  }
  const onChange = (e) => {
    setCredentials({...Credentials,[e.target.name]:e.target.value})
  }
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.leftContainer}>
          <img src="img/college-students.png" alt="" />
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.sectionMain}>
            <div className={styles.titleSection}>
              <div className={styles.logo}>
                <img src="/img/Sellular Logo.png" alt="" />
              </div>
              <h1>SELLULAR</h1>
              <p>Sellular is a Community, by Enthusiasts,<br /> for Enthusiasts, of Enthusiasts</p>
            </div>
            <form className={styles.inputSection} onSubmit={handleLogin}>
              <div className={styles.inputs}>
                <p className={styles.inputTitle}>Username</p>
                <input type="text" placeholder="Username" name="name" required onChange={onChange}  />
              </div>
              <div className={styles.inputs}>
                <p className={styles.inputTitle}>Password</p>
                <input type="password" placeholder="Password" name="password" required onChange={onChange} />
                <div className={styles.forgotPassword}>
                  <Link to="/forgotpassword">Forgot Password?</Link>
                </div>
              </div>
              
              {ErrorMsg && <div className={styles.errorMessage}>
                <p>Error: {ErrorMsg}</p>
              </div>}
              
              <button className={styles.submitUser} type="submit" value="LOGIN">Login</button>
            </form>
            <div className={styles.signUpSection}>
              <p>Don't have an account? <Link to="/signup">Sign Up</Link></p>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.light} ${styles.x1}`}></div>
      <div className={`${styles.light} ${styles.x2}`}></div>
      <div className={`${styles.light} ${styles.x3}`}></div>
      <div className={`${styles.light} ${styles.x4}`}></div>
      <div className={`${styles.light} ${styles.x5}`}></div>
      <div className={`${styles.light} ${styles.x6}`}></div>
      <div className={`${styles.light} ${styles.x7}`}></div>
      <div className={`${styles.light} ${styles.x8}`}></div>
      <div className={`${styles.light} ${styles.x9}`}></div>
    </>


  )
}

export default Login