import React, { useEffect, useRef, useState } from 'react'
import styles from './colleges.module.css'
import { Link } from 'react-router-dom';


const Colleges = ({ showAlert }) => {
    const searchRef = useRef(null);
    const [QueryParams, setQueryParams] = useState({ sort: "none", page: 1, search: "" })
    const updateQueryParams = (queryParams) => {
        const url = new URL("http://localhost:5000/colleges");

        if (queryParams.sort !== "none") {
            url.searchParams.set("sort", queryParams.sort);
        } else {
            url.searchParams.delete("sort");
        }

        if (queryParams.search.length > 1) {
            url.searchParams.set("search", queryParams.search);
        } else {
            url.searchParams.delete("search");
        }

        if (queryParams.page > 1) {
            url.searchParams.set("page", queryParams.page);
        } else {
            url.searchParams.delete("page");
        }

        return url;
    };
    useEffect(() => {
        const url = updateQueryParams(QueryParams);
        getColleges(url);
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [QueryParams])
    const getColleges = async (url) => {
        const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'auth-Token': localStorage.getItem('token')
            },
        });
        const json = await response.json();
        console.log(json)
        if (json.success) {
            setColleges(json)
        }
        else {
            showAlert(json.error, "warning")
        }
    }
    const onSearchChange = (e) => {
        e.preventDefault();
        setQueryParams((prev) => ({ ...prev, search: searchRef.current.value, page: 1 }))
    }
    const [Colleges, setColleges] = useState({ colleges: [], page: 1, count: 100 });
    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainTop}>
                <p>Colleges</p>
                <Link to="/addcollege" className={styles.addCollege}>Add College</Link>
            </div>
            <div className={styles.tableDivOuter}>
                <div className={styles.tableDivTop}>
                    <form className={styles.search} onSubmit={onSearchChange}>
                        <span className={`material-icons ${styles.searchIcon}`}>search</span>
                        <input type="text" placeholder="Search" ref={searchRef} />
                    </form>
                    <form className={styles.filter}>
                        <select name="filter" id="filter" onChange={(e) => { setQueryParams((prev) => ({ ...prev, sort: e.target.value })) }}>
                            <option value="none">Sort by: All</option>
                            <option value="1">Users: low to high</option>
                            <option value="-1">Users: high to low</option>
                        </select>
                        <span className="material-icons">filter_alt</span>
                    </form>
                </div>
                <table className={styles.tableMain}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Email</th>
                            <th>Contact</th>
                            <th>No. of Users</th>
                            <th>State</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Colleges.colleges.map((college) => (
                            <tr key={college?.emailDomain}>
                                <td>
                                    <div className={styles.applicantTitle}>
                                        {/* <div className={styles.profileIcon}>
                      <img src="img/profile-temp.jpg" alt="" />
                    </div> */}
                                        <p>{college?.collegeName}</p>
                                    </div>
                                </td>
                                <td>{college?.address}</td>
                                <td>{college?.emailDomain}</td>
                                <td>{college?.contact}</td>
                                <td>{college?.numberOfUsers}</td>
                                <td>
                                    {college?.collegeState}
                                    {/* <div className={`admin-btn ${user.verification.toLowerCase()}`} ></div> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* Custom Pagination */}
            <div className={styles.pagination}>
                <p>
                    Total Results : <span className={styles.currentCount}>{Colleges?.count}</span> 
                </p>
                <ul className={styles.pagesNumber}>
                    <li className={QueryParams.page === 1 ? styles.disable : ""} onClick={() => { setQueryParams(prev => ({ ...prev, page: (QueryParams.page > 1) ? (QueryParams.page - 1) : 1 })) }}>Prev</li>
                    <li className={styles.currentPage}>{Colleges?.page}</li>
                    <li className={Colleges?.count / 20 < QueryParams.page ? styles.disable : ""} onClick={() => { setQueryParams(prev => ({ ...prev, page: QueryParams.page + 1 })) }}>Next</li>
                </ul>
            </div>
        </div>
    )
}

export default Colleges