import React, { useEffect, useRef, useState } from 'react'
import styles from './verification.module.css'

const Verification = ({showAlert}) => {
    const searchRef = useRef(null);
    const [QueryParams, setQueryParams] = useState({ verified: "none", page: 1, search: "" })
    const updateQueryParams = (queryParams) => {
        const url = new URL("http://localhost:5000/verification");

        if (queryParams.verified !== "none") {
            url.searchParams.set("verified", queryParams.verified);
        } else {
            url.searchParams.delete("verified");
        }

        if (queryParams.search.length > 1) {
            url.searchParams.set("search", queryParams.search);
        } else {
            url.searchParams.delete("search");
        }

        if (queryParams.page > 1) {
            url.searchParams.set("page", queryParams.page);
        } else {
            url.searchParams.delete("page");
        }

        return url;
    };
    const getUsers = async (url) => {
        const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'auth-Token': localStorage.getItem('token')
            },
        });
        const json = await response.json();
        console.log(json)
        if (json.success) {
            setUsers(json)
        }
        else {
            showAlert(json.error, "warning")
        }
    }
    useEffect(() => {
        const url = updateQueryParams(QueryParams);
        getUsers(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [QueryParams])
    const onSearchChange = (e) => {
        e.preventDefault();
        setQueryParams((prev) => ({ ...prev, search: searchRef.current.value, page: 1 }))
    }
    const [Users, setUsers] = useState({ users: [], page: 1, count: {totalCount: 100, verifiedCount: 20, rejectCount: 20, pendingCount: 10, currentCount: 10} });
    return (
        <div className={styles.mainContainer}>
            <aside></aside>
            <main>
                <div className={styles.mainTop}>Verification</div>
                <div className={styles.applicationList}>
                    <div className={styles.application} onClick={() => setQueryParams((prev) => ({...prev,verified:"none"}))}>
                        <div className={styles.applicationTitle}>
                            <p className={styles.key}>Total Applications</p>
                            <p className={styles.count}>{Users.count?.totalCount}</p>
                        </div>
                        <div className={styles.applicationLogo}>
                                <span className={`material-icons ${styles.materialIcons} ${styles.listIcon}`}>list</span>
                        </div>
                    </div>
                    <div className={styles.application} onClick={() => setQueryParams((prev) => ({...prev,verified:"true"}))}>
                        <div className={styles.applicationTitle}>
                            <p className={styles.key}>Verified Applications</p>
                            <p className={styles.count}>{Users.count?.verifiedCount}</p>
                        </div>
                        <div className={styles.applicationLogo}>
                                <span className={`material-icons ${styles.materialIcons} ${styles.verifyIcon}`}>done_all</span>
                        </div>
                    </div>
                    <div className={styles.application} onClick={() => setQueryParams((prev) => ({...prev,verified:"pending"}))}>
                        <div className={styles.applicationTitle}>
                            <p className={styles.key}>Pending Applications</p>
                            <p className={styles.count}>{Users.count?.pendingCount}</p>
                        </div>
                        <div className={styles.applicationLogo}>
                                <span className={`material-icons ${styles.materialIcons} ${styles.pendingIcon}`}>pending_actions</span>
                        </div>
                    </div>
                    <div className={styles.application} onClick={() => setQueryParams((prev) => ({...prev,verified:"false"}))}>
                        <div className={styles.applicationTitle}>
                            <p className={styles.key}>Rejected Applications</p>
                            <p className={styles.count}>{Users.count?.rejectCount}</p>
                        </div>
                        <div className={styles.applicationLogo}>
                                <span className={`material-icons ${styles.materialIcons} ${styles.blockIcon}`}>block</span>
                        </div>
                    </div>
                </div>
                <div className={styles.tableDivOuter}>
                    <div className={styles.tableDivTop}>
                        <form className={styles.search} onSubmit={onSearchChange}>
                            <span className="material-icons search-icon">search</span>
                            <input type="text" placeholder="Search" ref={searchRef} />
                        </form>
                        <form className={styles.filter}>
                            <select name="filter" id="filter" onChange={(e)=>setQueryParams((prev)=>({...prev,verified: e.target.value}))}>
                                <option value="all">All</option>
                                <option value="true">Verified</option>
                                <option value="pending">Pending</option>
                                <option value="false">Rejected</option>
                            </select>
                            {QueryParams.verified !== "none" ? (
                                <span className="material-icons close-icon" style={{ cursor: 'pointer' }} onClick={()=>setQueryParams((prev)=>({...prev,verified:"none"}))}>
                                    close
                                </span>
                            ) : (
                                <span className="material-icons filter-icon" style={{ cursor: 'pointer' }}>
                                    filter_list
                                </span>
                            )}
                        </form>
                    </div>
                    <table className={styles.tableMain}>
                        <thead>
                            <tr>
                                <th>Applicant Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>College</th>
                                <th>Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Users.users.map((user) => (
                                <tr key={user.email}>
                                    <td>
                                        <div className={styles.applicantTitle}>
                                            <div className={styles.profileIcon}>
                                                <img src="img/profile-temp.jpg" alt="" />
                                            </div>
                                            <p>{user.name}</p>
                                        </div>
                                    </td>
                                    <td>{user.email}</td>
                                    <td>{user.phone}</td>
                                    <td>{user.collegeName}</td>
                                    <td>{user.dob}</td>
                                    <td>
                                        <div className={`${styles.verificationChip} ${styles[user.verified.toLowerCase()]}`}>
                                            {user.verified}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className={styles.pagination}>
                <p>
                    Total Results : <span className={styles.currentCount}>{Users?.count.currentCount}</span> 
                </p>
                <ul className={styles.pagesNumber}>
                    <li className={QueryParams.page === 1 ? styles.disable : ""} onClick={() => { setQueryParams(prev => ({ ...prev, page: (QueryParams.page > 1) ? (QueryParams.page - 1) : 1 })) }}>Prev</li>
                    <li className={styles.currentPage}>{Users?.page}</li>
                    <li className={Users?.count.currentCount / 20 < QueryParams.page ? styles.disable : ""} onClick={() => { setQueryParams(prev => ({ ...prev, page: QueryParams.page + 1 })) }}>Next</li>
                </ul>
            </div>
            </main>
        </div>
    )
}

export default Verification