import { useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/auth/login';
import SignUp from './components/auth/signup';
import Dashboard from './components/dashboard/dashboard';
import Navbar from './components/partials/navbar';
import Sidebar from './components/partials/sidebar'
import Colleges from './components/pages/colleges/colleges';
import Products from './components/pages/products/products';
import Verification from './components/pages/verification/verification';
import CollegeAdmin from './components/pages/Admin Pages/collegeAdmin/collegeAdmin';
import Profile from './components/pages/profile/profile';
import ClubsDashboard from './components/dashboard/dashboards/Clubs'
import CollegesDashboard from './components/dashboard/dashboards/Colleges'
import SellularSecuredDashboard from './components/dashboard/dashboards/SellularSecured'
import EventsDashboard from './components/dashboard/dashboards/Events'
import AugmountDashboard from './components/dashboard/dashboards/Augmount'
import TradeDashboard from './components/dashboard/dashboards/Trade'
import UsersDashboard from './components/dashboard/dashboards/Users'
import VouchersDashboard from './components/dashboard/dashboards/Vouchers'
import ForgotPassword from './components/auth/forgotPassword';
import Alert from './components/partials/alert';
import AddCollege from './components/pages/colleges/addCollege/addCollege';
import EditCarousel from './components/pages/Admin Pages/carouselUpdate/editCarousel';


function App() {
  // const [user, setuser] = useState(null);
  const [alert, setAlert] = useState(null);
  const showAlert = (message,type) => {
    setAlert({
      message: message,
      type: type
    });
    setTimeout(() => {
      setAlert(null)
    }, 3000);
  }
  
  return (
    <BrowserRouter>
      <Navbar showAlert={showAlert}/>
      <Alert alert={alert} setAlert={setAlert} />
      <Sidebar />
      <Routes>
        {/* Authentication Paths */}
        <Route exact path='/login' element={<Login showAlert={showAlert}/>} />
        <Route exact path='/signup' element={<SignUp showAlert={showAlert}/>} />
        <Route exact path='/forgotpassword' element={<ForgotPassword showAlert={showAlert} />} />

        {/* Main Routes */}
        <Route exact path='/colleges' element={<Colleges showAlert={showAlert} />} />
        <Route exact path='/products' element={<Products showAlert={showAlert}/>} />
        <Route exact path='/verification' element={<Verification showAlert={showAlert}/>} />
        <Route exact path='/createusers' element={<CollegeAdmin showAlert={showAlert}/>} />
        <Route exact path='/profile' element={<Profile showAlert={showAlert}/>} />

        {/* Dashboards Path */}
        <Route exact path='/dashboard' element={<Dashboard />} />
        <Route exact path='/dashboard/club' element={<ClubsDashboard />} />
        <Route exact path='/dashboard/college' element={<CollegesDashboard />} />
        <Route exact path='/dashboard/ecom' element={<SellularSecuredDashboard />} />
        <Route exact path='/dashboard/events' element={<EventsDashboard />} />
        <Route exact path='/dashboard/gold' element={<AugmountDashboard />} />
        <Route exact path='/dashboard/trade' element={<TradeDashboard />} />
        <Route exact path='/dashboard/user' element={<UsersDashboard />} />
        <Route exact path='/dashboard/voucher' element={<VouchersDashboard />} />

        {/* Other Routes */}
        <Route exact path='/addcollege' element={<AddCollege showAlert={showAlert} />} />
        <Route exact path='/editcarousel' element={<EditCarousel showAlert={showAlert} />} />
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
