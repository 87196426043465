import React, { useEffect, useState } from 'react'
import styles from './profile.module.css'
import { Link } from 'react-router-dom';


const Profile = ({ showAlert }) => {
    const [activeSection, setActiveSection] = useState('teams');
    const changeActiveSection = (section) => {
        setActiveSection(section);
    } 
    useEffect(()=>{
        const url = "http://localhost:5000/profile";
        getUser(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const getUser = async(url) =>{
        const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'auth-Token': localStorage.getItem('token')
            },
        });
        const json = await response.json();
        console.log(json)
        if (json.success) {
            setUser(json)
        }
        else {
            showAlert(json.error, "warning");
        }
    }
    const [User, setUser] = useState({teamMessages: [],userTasks: [], userActivities: []});
    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainTop}>
                <p>Profile</p>
            </div>
            <div className={styles.main}>
                <div className={styles.profile}>
                    <div className={styles.pic}>
                        <img src="/img/profile_icon.png" alt="Profile Pic" />
                        <p>
                            {User?.username} <br/> ({User?.team})
                        </p>
                    </div>
                    <div className={styles.icons}>
                        <button>
                            <span className="material-symbols-outlined">call</span>
                        </button>
                        <button>
                            <span className="material-symbols-outlined">mail</span>
                        </button>
                        <button>
                            <span className="material-symbols-outlined">chat</span>
                        </button>
                    </div>
                    <div className={styles.details}>
                        <p><span>Phone:</span>
                            {User?.phone}
                        </p>
                        <p><span>Email:</span>
                            {User?.email}
                        </p>
                        <p><span>Institution:</span>
                            {User?.college}
                        </p>
                    </div>
                    <div className={styles.change}>
                        <button><Link to="/forgotpassword" style={{"color":"white","textDecoration":"none"}}>Change Password</Link></button>
                        <button>Change Details</button>
                    </div>
                </div>
                <div className={styles.subSections}>
                    <ul>
                        <li key={'teams'} className = {activeSection === 'teams' ? styles.subActive : 'notActive'} onClick={()=>changeActiveSection('teams')} id="teams">Teams</li>
                        <li key={'tasks'} className = {activeSection === 'tasks' ? styles.subActive : 'notActive'} onClick={()=>changeActiveSection('tasks')} id="tasks">Tasks</li>
                        <li key={'history'} className = {activeSection === 'history' ? styles.subActive : 'notActive'} onClick={()=>changeActiveSection('history')} id="history">History</li>
                    </ul>
                    {activeSection === 'teams' && (<div key={'teams'} className={styles.teams}>
                        {User?.teamMessages.map((teamMessage) => (
                            <div className={styles.team} key={teamMessage?._id}>
                                <div className={styles.title}>
                                    <p>
                                        {teamMessage?.teamMember}
                                    </p>
                                    <p>
                                        {teamMessage?.message.time}
                                    </p>
                                </div>
                                <span className={`material-symbols-outlined ${styles.taskIcons}`}>group</span>
                                <p className={styles.text}>
                                    {teamMessage?.message.msg}
                                </p>
                            </div>
                        ))}
                    </div>)}
                    {activeSection === 'tasks' && (<div key={'tasks'} className={styles.tasks}>
                        {User?.userTasks.map((userTask) => (
                            <div className={styles.team} key={userTask?._id}>
                                <div className={styles.title}>
                                    <p>
                                        {userTask?.task.task_name}
                                    </p>
                                    <p>
                                        {userTask?.task.task_status}
                                    </p>
                                </div>
                                <span className={`material-symbols-outlined ${styles.taskIcons}`}>task_alt</span>
                                <p className={styles.text}>
                                    {userTask?.task.task_description}
                                </p>
                            </div>
                        ))}
                    </div>)}
                    {activeSection === 'history' && (<div key={'history'} className={styles.history}>
                        {User?.userActivities.map((activity) => (
                            <div className={styles.team} key={activity?._id}>
                                <div className={styles.title}>
                                    <p>
                                        {activity?.user}
                                    </p>
                                    <p>
                                        {activity?.time}
                                    </p>
                                </div>
                                <span className={`material-symbols-outlined ${styles.taskIcons}`}>description</span>
                                <p className={styles.text}>
                                    {activity?.task}
                                </p>
                            </div>
                        ))}
                    </div>)}
                </div>
            </div>
        </div>
    )
}

export default Profile