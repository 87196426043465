import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './sidebar.module.css'

const Sidebar = () => {
    const [IsActive, setIsActive] = useState(null);
    const location = useLocation()
    const currentUrl = location.pathname;
    useEffect(() => {
      setIsActive(currentUrl);
    }, [currentUrl])
    
    const isLoginOrSignup = location.pathname === '/login' || location.pathname === '/signup' || location.pathname === '/forgotpassword';

    if (isLoginOrSignup) {
        return null
    }
    const handleLogout = () => {
        localStorage.removeItem('token');
    }
    
    return (
        <div className={styles.container}>
            <div className={styles.navigation}>
                <ul>
                    <li className={styles.logo}>
                        <span className={styles.icon1}><img src="/img/Sellular Logo.png" alt="" /></span>
                        <Link to="#">
                            <span className={styles.title}>SELLULAR</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard">
                            <span className={`material-symbols-outlined ${styles.sideIcons}`}>dashboard</span>
                            <span>Dashboard
                                <span className={`${styles.active} ${IsActive === '/dashboard' ? styles.active1 : 'inactive'}` }></span>
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/colleges">
                            <span className={`material-symbols-outlined ${styles.sideIcons}`}>school</span>
                            <span>Colleges
                                <span className={`${styles.active} ${IsActive === '/colleges' ? styles.active1 : 'inactive'}` }></span>
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/products">
                            <span className={`material-symbols-outlined ${styles.sideIcons}`}>group</span>
                            <span>Products
                                <span className={`${styles.active} ${IsActive === '/products' ? styles.active1 : 'inactive'}` }></span>
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/verification">
                            <span className={`material-symbols-outlined ${styles.sideIcons}`}>done_all</span>
                            <span>Verification
                                <span className={`${styles.active} ${IsActive === '/verification' ? styles.active1 : 'inactive'}` }></span>
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/createusers">
                            <span className={`material-symbols-outlined ${styles.sideIcons}`}>person_add</span>
                            <span>College Admin
                                <span className={`${styles.active} ${IsActive === '/createusers' ? styles.active1 : 'inactive'}` }></span>
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/profile">
                            <span className={`material-symbols-outlined ${styles.sideIcons}`}>person</span>
                            <span>User
                                <span className={`${styles.active} ${IsActive === '/profile' ? styles.active1 : 'inactive'}` }></span>
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/editcarousel">
                            <span className={`material-symbols-outlined ${styles.sideIcons}`}>view_carousel</span>
                            <span>Carousel Edit
                                <span className={`${styles.active} ${IsActive === '/editcarousel' ? styles.active1 : 'inactive'}` }></span>
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/login" onClick={handleLogout}>
                            <span className={`material-symbols-outlined ${styles.sideIcons}`}>logout</span>
                            <span>Log Out
                                <span className={`${styles.active} ${IsActive === '/login' ? styles.active1 : 'inactive'}` }></span>
                            </span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Sidebar