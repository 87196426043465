import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const Navbar = ({showAlert}) => {    
    const navigate = useNavigate();
    async function checkUser(){
        console.log("hello")
        const response = await fetch(`http://localhost:5000/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': localStorage.getItem('token')
          },
        });
        const json = await response.json();
        console.log(json)
        if(!json.success){
          showAlert(json.error,'warning');
          navigate('/login')
        }
      }
      useEffect(() => {
        if(localStorage.getItem('token')){
          checkUser();
        }
        else{
            navigate('/login')
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
    const location = useLocation();

    const isLoginOrSignup = location.pathname === '/login' || location.pathname === '/signup' || location.pathname === '/forgotpassword';

    if (isLoginOrSignup) {
        return null; 
    }

    return (
        null
    );
}

export default Navbar