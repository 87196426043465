import React from 'react';
import styles from './dashboard.module.css';
import { Link } from 'react-router-dom';

const Dashboard = () => {
    return (
        <section className={styles.mainContainer}>
            <div className={styles.card}>
                <Link to="club">
                    <img src="/img/college-dash.jpg" alt="" />
                    <h1 className={styles.cardTitle}>Clubs</h1>
                </Link>
            </div>
            <div className={styles.card}>
                <Link to="college">
                    <img src="/img/events-dash.jpg" alt="" />
                    <h1 className={styles.cardTitle}>College</h1>
                </Link>
            </div>
            <div className={styles.card}>
                <Link to="ecom">
                    <img src="/img/buy-sell-dash.jpg" alt="" />
                    <h1 className={styles.cardTitle}>Sellular Secured</h1>
                </Link>
            </div>
            <div className={styles.card}>
                <Link to="events">
                    <img src="/img/ecom-dash.jpg" alt="" />
                    <h1 className={styles.cardTitle}>Events</h1>
                </Link>
            </div>
            <div className={styles.card}>
                <Link to="gold">
                    <img src="/img/users-dash.jpg" alt="" />
                    <h1 className={styles.cardTitle}>Augmont</h1>
                </Link>
            </div>
            <div className={styles.card}>
                <Link to="trade">
                    <img src="/img/users-dash.jpg" alt="" />
                    <h1 className={styles.cardTitle}>Trade</h1>
                </Link>
            </div>
            <div className={styles.card}>
                <Link to="user">
                    <img src="/img/users-dash.jpg" alt="" />
                    <h1 className={styles.cardTitle}>Users</h1>
                </Link>
            </div>
            <div className={styles.card}>
                <Link to="vouchers">
                    <img src="/img/users-dash.jpg" alt="" />
                    <h1 className={styles.cardTitle}>Vouchers</h1>
                </Link>
            </div>
        </section>
    )
}

export default Dashboard;