import React, { useEffect, useState } from 'react'
import styles from './collegeAdmin.module.css'

const CollegeAdmin = ({showAlert}) => {
    useEffect(() => {
        const url = "http://localhost:5000/createusers"
        getColleges(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getColleges = async (url) => {
        const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'auth-Token': localStorage.getItem('token')
            },
        });
        const json = await response.json();
        console.log(json)
        if (json.success) {
            setTeamMembers(json)
        }
        else {
            showAlert(json.error, "warning")
        }
    }
    const [TeamMembers, setTeamMembers] = useState({ teamMembers: [] });
    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainTop}>
                Create Users
            </div>
            <div className={styles.tableDivOuter}>
                <div className={styles.tableDivTop}>
                    <div className={styles.search}>
                        <span className={`material-icons search-icon`}>search</span>
                        <input type="text" placeholder="Search" />
                    </div>
                    <div className={styles.filter}>
                        <select name="filter" id="filter">
                            <option value="all">All</option>
                            <option value="verified">Verified</option>
                            <option value="pending">Pending</option>
                            <option value="rejected">Rejected</option>
                        </select>
                        <span className={`material-icons filter-icon`}>filter_list</span>
                    </div>
                </div>
                <table className={styles.tableMain}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>College</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Status</th>
                            <th>Admin</th>
                        </tr>
                    </thead>
                    <tbody>
                        {TeamMembers.teamMembers.map((teamMember) => (
                            <tr key={teamMember?.id}>
                                <td>
                                    <div className={styles.applicantTitle}>
                                        <div className={styles.profileIcon}>
                                            <img src="img/profile-temp.jpg" alt="" />
                                        </div>
                                        <p>{teamMember?.name}</p>
                                    </div>
                                </td>
                                <td>{teamMember?.college}</td>
                                <td>{teamMember?.email}</td>
                                <td>{teamMember?.phone}</td>
                                <td>{teamMember?.status}</td>
                                <td>
                                    <div className={`${styles.adminBtn}`}>
                                        Add
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className={styles.pagination}>
                <ul className={styles.pagesNumber}>
                    <li>Previous</li>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>Next</li>
                </ul>
            </div>
        </div>
    )
}

export default CollegeAdmin