import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './signup.module.css';

const SignUp = ({showAlert}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirm: '',
    dob: '',
    phone: '',
    gender: '',
    team: '',
    profileImage: '',
  });

  const [errorMsg, setErrorMsg] = useState(null);
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('password', formData.password);
    formDataToSend.append('confirm', formData.confirm);
    formDataToSend.append('profileImage', formData.profileImage); // Append the image file to the form data
    formDataToSend.append('dob', formData.dob);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('gender', formData.gender);
    formDataToSend.append('team', formData.team);

    formDataToSend.forEach((value,parent)=>{
        console.log(parent+":"+value)
    })

    const response = await fetch('http://localhost:5000/signup', {
      method: 'POST',
      body: formDataToSend,
    });
    console.log(response)
    const json = await response.json();
    console.log(json);

    if (json.success) {
        localStorage.setItem('token',json.authToken);
      navigate('/dashboard');
    } else {
      setErrorMsg(json.error);
      showAlert(json.error, 'danger');
    }
  };

  const handleChange = (e) => {
      console.log(e.target.name+":"+e.target.value);
    if (e.target.name === 'profileImage') {
        console.log(e.target.name+":"+e.target.files[0])
        setFormData({
          ...formData,
          [e.target.name]: e.target.files[0],
        });
      } else {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.leftContainer}>
          <img src="img/college-students.png" alt="" />
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.sectionMain}>
            <div className={styles.titleSection}>
              <div className={styles.logo}>
                <img src="/img/Sellular Logo.png" alt="" />
              </div>
              <h1>SELLULAR</h1>
              <p>Sellular is a Community, by Enthusiasts,<br /> for Enthusiasts, of Enthusiasts</p>
            </div>
            {errorMsg && (
              <div className={styles.errorMessage}>
                <p>Error: {errorMsg}</p>
              </div>
            )}
            <form className={styles.inputSection} onSubmit={handleSignup}>
              <div className={styles.formSection}>
                <div className={styles.inputs}>
                  <p className={styles.inputTitle}>Name</p>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className={styles.inputs}>
                  <p className={styles.inputTitle}>Email</p>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className={styles.inputs}>
                  <p className={styles.inputTitle}>Password</p>
                  <input
                    type="password"
                    placeholder="Enter Password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className={styles.inputs}>
                  <p className={styles.inputTitle}>Confirm Password</p>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    name="confirm"
                    value={formData.confirm}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className={styles.inputs}>
                  <p className={styles.inputTitle}>Profile Image</p>
                  <label className={styles.profileSelect} htmlFor="profileImage">
                    Select Profile
                    <span className="material-icons">upload_file</span>
                  </label>
                  <input
                    className={styles.profileImage}
                    type="file"
                    id="profileImage"
                    name="profileImage"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={styles.formSection}>
                <div className={styles.inputs}>
                  <p className={styles.inputTitle}>Date of Birth</p>
                  <input
                    type="date"
                    name="dob"
                    placeholder="Enter DOB"
                    value={formData.dob}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className={styles.inputs}>
                  <p className={styles.inputTitle}>Phone</p>
                  <input
                    type="tel"
                    name="phone"
                    maxLength="10"
                    placeholder="Enter Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className={styles.inputs}>
                  <p className={styles.inputTitle}>Gender</p>
                  <select
                    className={styles.genderSelect}
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="others">Others</option>
                  </select>
                </div>
                <div className={styles.inputs}>
                  <p className={styles.inputTitle}>Team</p>
                  <select
                    className={styles.customSelect}
                    id="team"
                    name="team"
                    value={formData.team}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Team</option>
                    <option value="growth">Growth</option>
                    <option value="tech">Tech</option>
                    <option value="social">Social</option>
                    <option value="design">Design</option>
                    <option value="foundersOffice">Founders Office</option>
                  </select>
                </div>
                <div className={styles.inputs}>
                  <p className={styles.inputTitle} style={{ visibility: 'hidden' }}>
                    Sign Up
                  </p>
                  <button className={styles.submitUser} type="submit">
                    Sign Up
                  </button>
                </div>
              </div>
            </form>
            <div className={styles.signupSection}>
              <p>
                Already a member? <Link to="/login">Sign In</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.light} ${styles.x1}`}></div>
      <div className={`${styles.light} ${styles.x2}`}></div>
      <div className={`${styles.light} ${styles.x3}`}></div>
      <div className={`${styles.light} ${styles.x4}`}></div>
      <div className={`${styles.light} ${styles.x5}`}></div>
      <div className={`${styles.light} ${styles.x6}`}></div>
      <div className={`${styles.light} ${styles.x7}`}></div>
      <div className={`${styles.light} ${styles.x8}`}></div>
      <div className={`${styles.light} ${styles.x9}`}></div>
    </>
  );
};

export default SignUp;
